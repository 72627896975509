<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item :to="{ name: 'log-points' }">
        <div id="store-credit" class="d-flex align-items-center">
          <b-img
            src="@/assets/images/icons/icons8-stack-of-coins-64.png"
            height="30px"
            width="35px"
          />
          <span class="font-weight-bold">LP {{ storeCredit }}</span>
          <b-tooltip target="store-credit" triggers="hover" no-fade>
            Click to refresh Log Points balance.
          </b-tooltip>
        </div>
      </b-nav-item>
      <b-nav-item>
        <Locale />
      </b-nav-item>
      <b-nav-item-dropdown
        class="dropdown-notification mr-25 top-right-menu"
        menu-class="dropdown-menu-media"
        right
      >
        <template #button-content>
          <b-avatar
            v-b-tooltip.hover.bottom="'Notification'"
            size="40"
            badge="2"
          >
            <feather-icon icon="BellIcon" size="21" />
          </b-avatar>
        </template>
        <li class="dropdown-menu-header">
          <div class="dropdown-header d-flex">
            <h4 class="notification-title mb-0 mr-auto">
              {{ $t('src.layouts.components.navbar.notifications') }}
            </h4>
            <!-- <b-badge
              pill
              variant="light-primary"
            >{{ $t('src.layouts.components.navbar.new') }}</b-badge> -->
          </div>
        </li>
        <!-- notif content -->
        <!-- Notifications -->
        <vue-perfect-scrollbar
          v-once
          class="scrollable-container media-list scroll-area"
          tagname="li"
          :setting="{
            maxScrollbarLength: 60,
            wheelPropagation: false
          }"
        >
          <!-- Account Notification -->
          <b-link>
            <b-media>
              <template #aside>
                <b-avatar size="32">
                  <feather-icon icon="ArchiveIcon" size="21" />
                </b-avatar>
              </template>
              <p class="media-heading">
                <span class="font-weight-bolder">{{
                  $t('src.layouts.components.navbar.documents')
                }}</span>
              </p>
              <small class="notification-text">{{
                $t('src.layouts.components.navbar.hiDN')
              }}</small>
            </b-media>
          </b-link>
          <b-link>
            <b-media>
              <template #aside>
                <b-avatar size="32">
                  <feather-icon icon="ArchiveIcon" size="21" />
                </b-avatar>
              </template>
              <p class="media-heading">
                <span class="font-weight-bolder">{{
                  $t('src.layouts.components.navbar.updates')
                }}</span>
              </p>
              <small class="notification-text">{{
                $t('src.layouts.components.navbar.hiYM')
              }}</small>
            </b-media>
          </b-link>
        </vue-perfect-scrollbar>
      </b-nav-item-dropdown>
      <b-nav-item class="top-right-menu" :to="{ name: 'profile' }">
        <b-avatar
          v-b-tooltip.hover.bottom.v-dark="'My Profile'"
          :src="`${$s3URL}uploads/user/${user.pic}`"
        />
      </b-nav-item>
      <b-nav-item class="top-right-menu" :to="{ name: 'my-cart' }">
        <b-avatar
          v-b-tooltip.hover.bottom.v-dark="'My Cart ( ' + cartCount + ' )'"
          size="40"
          :badge="cartCount"
        >
          <feather-icon icon="ShoppingCartIcon" size="21" />
        </b-avatar>
      </b-nav-item>
      <b-nav-item class="top-right-menu" @click="logoutSession">
        <b-avatar v-b-tooltip.hover.bottom.v-dark="'Sign Out'">
          <feather-icon icon="LogOutIcon" size="20" />
        </b-avatar>
      </b-nav-item>
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav, BNavItem, BNavItemDropdown } from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import { getUserData } from '@/auth/utils'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import axios from '@axios'

export default {
  components: {
    VuePerfectScrollbar,
    BLink,
    BNavItemDropdown,
    BNavbarNav,
    BNavItem,
    // Navbar Components
    DarkToggler,
    Locale
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      user: { name: 'Name' },
      roles: { 1: 'Admin', 2: 'B2BAccount' },
      cartCount: '0',
      storeCredit: 0
    }
  },
  created() {
    this.user = getUserData()
    this.fetchCartItemsCount()
    this.fetchStoreCredits()
  },
  methods: {
    logoutSession() {
      localStorage.removeItem('userData')
      localStorage.removeItem('accessToken')
      this.$router.replace('/login')
    },
    async fetchCartItemsCount() {
      const response = await axios.get('/cart-items/count')
      if (response.status === 200) {
        this.cartCount = `${response.data}`
      }
    },
    async fetchStoreCredits() {
      this.$http.get(`logpoint/get_my_logpoint`).then((response) => {
        if (response.status === 200) {
          this.storeCredit = response.data.data.logpoint
        }
      })
    }
  }
}
</script>
