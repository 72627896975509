import i18n from '@/libs/i18n'

export default [
  {
    title: i18n.t('Dashboard'),
    route: 'dashboard',
    icon: 'HomeIcon'
  },
  {
    title: i18n.t('Create Delivery'),
    route: 'createdelivery',
    icon: 'ShoppingCartIcon'
  },
  {
    title: 'Freight Forwarding',
    icon: 'SendIcon',
    children: [
      {
        title: i18n.t('Create Delivery'),
        route: 'fleetforwardingcreatedelivery',
        icon: 'ShoppingCartIcon'
      },
      {
        title: 'Order Tracker',
        route: 'fleetforwardingordertracker',
        icon: 'LayersIcon'
      },
      {
        title: 'Delivery History',
        route: 'fleetforwardingdeliveryhistory',
        icon: 'DollarSignIcon'
      }
    ]
  },
  {
    title: 'Chartered Truck',
    icon: 'TruckIcon',
    children: [
      {
        title: i18n.t('Create Delivery'),
        route: 'Charteredtruckcreatedelivery',
        icon: 'ShoppingCartIcon'
      },
      {
        title: 'Order Tracker',
        route: 'Charteredtruckordertracker',
        icon: 'LayersIcon'
      },
      {
        title: 'Delivery History',
        route: 'Charteredtruckdeliveryhistory',
        icon: 'DollarSignIcon'
      }
    ]
  },
  {
    title: i18n.t('Bulk Delivery'),
    route: 'bulkdelivery',
    icon: 'ShoppingBagIcon'
  },
  {
    title: i18n.t('Delivery Transaction'),
    route: 'deliverytransaction',
    icon: 'DollarSignIcon'
  },
  {
    title: i18n.t('Delivery History'),
    route: 'deliveryhistory',
    icon: 'ClockIcon'
  },
  {
    title: i18n.t('Refund Requests'),
    route: 'RefundRequests',
    icon: 'RotateCcwIcon'
  },
  {
    title: i18n.t('Track & Trace'),
    route: 'trackorder',
    icon: 'GitPullRequestIcon'
  },
  {
    title: i18n.t('Inquiries'),
    route: 'Inquiries',
    icon: 'FileTextIcon'
  },
  {
    title: i18n.t('Logpoints'),
    route: 'log-points',
    icon: 'SearchIcon'
  }
]
